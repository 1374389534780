<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { RouteRecord } from 'vue-router'
  import { useGameContentStore } from '~/store/GameContent'
  import useTranslation from '~/composables/Translation/Translation'
  import { computed, useRoute } from '#imports'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'
  import {
    GameCinemaSectionEntry,
    GameCinemaSectionId,
  } from '~/model/GameCinemaSection/GameCinemaSection'
  import { useGameStateStore } from '~/store/GameState'
  import { GameTrouvailleListContent } from '~/model/GameTrouvaille/GameTrouvaille'
  import useGameCinemaSection from '~/composables/GameCinemaSection/GameCinemaSection'
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'
  import useTrouvailleEquipment from '~/composables/TrouvailleEquipment/TrouvailleEquipment'
  import useDebug from '~/composables/Debug/Debug'

  const gameContentStore = useGameContentStore()
  const gameStateStore = useGameStateStore()

  const { equipmentList } = storeToRefs(gameContentStore)
  const { unlockedTrouvailleList } = storeToRefs(gameStateStore)
  const { isDebug } = useDebug()
  const route = useRoute()
  const { toRoute, cinemaSectionParam } = useRouteRecord()
  const { t } = useTranslation()
  const { isEquipped } = useTrouvailleEquipment()
  const { gameCinemaSectionDict } = useGameCinemaSection()

  const section = computed<GameCinemaSectionEntry | undefined>(() => {
    const param = cinemaSectionParam.value as GameCinemaSectionId
    return gameCinemaSectionDict[param] as GameCinemaSectionEntry
  })

  const availableEquipmentList = computed<Array<GameTrouvailleListContent>>(() => {
    if (!equipmentList.value) {
      return []
    }

    return equipmentList.value.filter(({ id }) => {
      return unlockedTrouvailleList.value.has(id) && !isEquipped(id)
    })
  })

  const toStorage = computed<RouteRecord>(() => {
    return toRoute({ name: RouteRecordId.GameCinemaStorage })
  })
</script>

<template>
  <div class="c-sidebar-content-cinema-section s-sidebar-content u-typography-root">
    <h1 class="s-sidebar-content__title">
      {{ t(`cinema-section.${section.id}`) }}
    </h1>
    <div class="u-markdown-root" v-html="t('sidebar-content-cinema-section.introduction')" />

    <div class="s-sidebar-content__entries">
      <ul class="c-sidebar-content-cinema-section__list u-reset">
        <li
          v-for="id in section.equipmentCategoryIds"
          :key="id"
          class="c-sidebar-content-cinema-section__entry"
        >
          <ListEquipment :equipment-category-id="id" :equipment-list="availableEquipmentList" />
        </li>
      </ul>
    </div>
    <div v-if="isDebug" class="s-sidebar-content__entries">
      <div class="c-sidebar-content-cinema-section__storage">
        <h2 class="s-sidebar-content__entries-title">
          {{ t('sidebar-content-cinema-section.storage-title') }}
        </h2>
        <div class="s-sidebar-content__entries-content">
          <NuxtLink :to="toStorage" class="btn btn--primary btn--small">
            {{ t('sidebar-content-cinema-section.action-storage.label') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .c-sidebar-content-cinema-section__list {
    display: grid;
    grid-row-gap: 32px;
  }
</style>
